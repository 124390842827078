import React, { useEffect, useState } from 'react';
import SiteLayout from '../components/SiteLayout';

import { Location, navigate } from '@reach/router';
import { Button, Spin } from 'antd';
import qs from 'query-string';

// @ts-ignore
import Terms from '../../static/terms.inline.svg';

// @ts-ignore
import { info, image, backbutton } from './valider.module.scss';

import { Link } from 'gatsby';

import { ValidationStatus } from '../service';
import { useTrackedSessionStoreStore } from "../state/sessionStore";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";

export function ValiderComponent({ location, isEmbeded }) {

    const [loading, setLoading] = useState<boolean>(true);
    //const [authUrl] = useGlobalState('authUrl');
    //const [sessionStatus] = useGlobalState('sessionStatus');

    const sessionStore = useTrackedSessionStoreStore();

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    useEffect(() => {
        check();
    }, []);

    async function check() {
        const parsed = qs.parse(location.search);
        const sid = parsed.state as string;
        const code = parsed.code as string;
        const error = parsed.error;

        if (error) {
            await sessionStore.fail({ sessionId: sid as string });
            window.close();
        } else {
            doValidation(sid, code);
        }
    }

    async function doValidation(sid: string, code: string) {
        let embeded = "";
        isEmbeded ? embeded = "/embeded/" : embeded = "/";

        try {
            const resultCode = await sessionStore.code({ sessionId: sid,  code });
            const resultValidate = await sessionStore.validate({ sessionId: sid });
            setLoading(false);

            // Validation is OK
            if (resultValidate.validationStatus === ValidationStatus.NUMBER_1) {
                navigate(embeded + "aksepter?sid=" + sid);
            } else {
                // Set status and show different view;
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
            navigate(embeded + "feil?sid=" + sid);
        }
    }

    return (
        <>
            {loading ? <div style={{ marginBottom: 20 }}><Spin indicator={antIcon} /></div> :
                <p className={info}>
                    Du ble ikke validert som eier av denne måleren. Dersom du mener det er feil ta kontakt med nettselskapet ditt.
                </p>
            }
        </>
    );
}

function Valider() {
    return (<Location>
        {({ location, navigate }) => (
            <SiteLayout location={location}>
                <Terms className={image} />
                <br />
                <h1>Aktiver HAN</h1>
                <p style={{ fontWeight: 400 }}>Sjekker at du står oppført som eier</p>
                <br />

                <ValiderComponent location={location} isEmbeded={false} />
                <Link to="/velg"><Button icon={<ArrowLeftOutlined />} className={backbutton}>Tilbake</Button></Link>
            </SiteLayout>
        )}
    </Location>);
}

export default Valider;
