import React, { useEffect, useState } from 'react';
import { IndexComponent } from '..';
import SiteLayoutEmbeded from '../../components/SiteLayoutEmbeded';
import { ValiderComponent } from '../valider';
import { Location, Link } from '@reach/router';
import { Button } from 'antd';

// @ts-ignore
import {backbutton} from './embeded.module.scss';

function Index() {
    return (
        <Location>
            {({ location }) => (
                <SiteLayoutEmbeded>
                    <ValiderComponent location={location} isEmbeded={true} />
                    <Link to="/embeded/velg"><Button icon="arrow-left" className={backbutton}>Tilbake</Button></Link>
                </SiteLayoutEmbeded>
            )}
        </Location>
    );
}

export default Index;
